<template>
  <div class="container-fluid">
    <h1>신청 완료</h1>
    <p>신청서가 성공적으로 제출되었습니다.</p>
  </div>
</template>

<script>
export default {
  title: '신청 제출완료',
};
</script>

<style></style>
